body {
  margin: 0;
  font-family: TitilliumWeb-Regular, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: local("TitilliumWeb-Regular"),
    url("./fonts/TitilliumWeb-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoEmoji-Bold";
  src: local("NotoEmoji-Bold"),
    url("./fonts/NotoEmoji-Bold.ttf") format("truetype");
}