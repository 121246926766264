.Login {
  flex-direction: column;
  font-family: inherit;
  justify-content: center;
}

.Login-Item {
  display: flex;
  max-height: 10vh;
  font-size: 3vh;
  color: var(--color-black-1);
  padding: 1% 3% 1% 3%;
  margin: 1vh 5vw 0vh;
  flex-direction: column;
  font-family: inherit;
}

.Text-Input {
  font-size: inherit;
  padding: inherit;
  border-radius: 5ch;
  font-family: inherit;
}

.Button-Input {
  font-size: inherit;
  padding: inherit;
  max-width: 50vb;
  background-color: var(--color-blue);
  color: var(--color-white);
  border-radius: 0.5ch;
  margin-top: 3%;
  align-self: center;
  font-family: inherit;
}

.Button-Input:disabled {
  background-color: var(--color-black-4);
  color: var(--color-black-1);
  border-style: inset;
}

.Error-Label {
  font-size: 0.8em;
  color: var(--color-red);
  font-family: inherit;
}

.Loading-Spinner {
  width: 10%;
  align-self: center;
}

#environment-label {
  font-size: 3vh;
  color: var(--color-red);
  font-family: inherit;
  position: fixed;
  align-self: center;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, -50%);
}

#version-number {
  font-size: 3vh;
  color: var(--color-black-3);
  font-family: inherit;
  position: fixed;
  align-self: center;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -50%);
}
