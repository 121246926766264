.home-header-hamburger-icon-svg.safari {
  position: absolute;
  left: 10%;
}

/* ---------- Menu Opening ---------- */
.home-header-hamburger-icon-svg.open {
  animation: MenuSpinOpen 0.2s linear;
  animation-fill-mode: forwards;
}

.home-header-hamburger-icon-svg.safari.open {
  animation: MenuSpinOpenSafari 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-top-line.open {
  animation: TopBarMenuOpen 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-middle-line.open {
  animation: MiddleBarMenuOpen 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-bottom-line.open {
  animation: BottomBarMenuOpen 0.2s linear;
  animation-fill-mode: forwards;
}

@keyframes MenuSpinOpen {
  to {
    transform: rotate(180deg);
  }
}

@keyframes MenuSpinOpenSafari {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(0) rotate(180deg);
  }
}

@keyframes TopBarMenuOpen {
  to {
    d: path("M50,50 L450,400");
  }
}

@keyframes MiddleBarMenuOpen {
  to {
    opacity: 0;
    d: path("M200,225 L300,225");
  }
}

@keyframes BottomBarMenuOpen {
  to {
    d: path("M50,400 L450,50");
  }
}

/* ---------- Menu Closing ---------- */

.home-header-hamburger-icon-svg.closed {
  animation: MenuSpinClose 0.2s linear;
  animation-fill-mode: forwards;
}

.home-header-hamburger-icon-svg.safari.closed {
  animation: MenuSpinCloseSafari 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-top-line.closed {
  animation: TopBarMenuClose 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-middle-line.closed {
  animation: MiddleBarMenuClose 0.2s linear;
  animation-fill-mode: forwards;
}

.hamburger-bottom-line.closed {
  animation: BottomBarMenuClose 0.2s linear;
  animation-fill-mode: forwards;
}

@keyframes MenuSpinClose {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes MenuSpinCloseSafari {
  from {
    transform: scale(0) rotate(180deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes TopBarMenuClose {
  from {
    d: path("M50,50 L450,400");
  }
  to {
    d: path("M50,50 L450,50");
  }
}
@keyframes MiddleBarMenuClose {
  from {
    opacity: 0;
    d: path("M200,225 L300,225");
  }
  to {
    opacity: 1;
    d: path("M50,225 L450,225");
  }
}

@keyframes BottomBarMenuClose {
  from {
    d: path("M50,400 L450,50");
  }
  to {
    d: path("M50,400 L450,400");
  }
}
