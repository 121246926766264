.part-details-container {
  position: fixed;
  overflow: hidden;
  top: 13.5vh;
  background-color: var(--color-black-2);
  height: calc(76.5vh + (env(safe-area-inset-top) - env(safe-area-inset-bottom)*2));
  width: 100vw;
  z-index: 10;
}

.part-details-container-pla {
  position: fixed;
  overflow: hidden;
  top: 13.5vh;
  background-color: var(--color-black-2);
  height: calc(83.5vh + (env(safe-area-inset-top) - env(safe-area-inset-bottom)*2));
  width: 100vw;
  z-index: 10;
}

.part-details-content {
  position: fixed;
  overflow-y: scroll;
  height: 66vh;
  width: 92vw;
  z-index: 11;
  padding-right: 2vw;
  padding-left: 5vw;
  font-size: 5vw;
}

.part-details-content-pla {
  position: fixed;
  overflow-y: scroll;
  height: 74vh;
  width: 92vw;
  z-index: 11;
  padding-right: 2vw;
  padding-left: 5vw;
  font-size: 5vw;
}

.bin-input-box {
  width: 40%;
  align-self: center;
  border-radius: 3vw;
  text-align: center;
  height: 4.5vh;
  padding-left: 2vw;
  font-size: 4vh;
  font-family: inherit;
  text-transform: uppercase;
}

.barcode-input-box {
  width: 90%;
  border-radius: 3vw;
  text-align: center;
  height: 4.5vh;
  padding-left: 2vw;
  font-size: 4vh;
  font-family: inherit;
  text-transform: uppercase;
}

.part-details-description {
  font-size: 5vw;
  padding-left: 1vw;
  padding-top: 1vh;
  text-align: left;
}

.bin-details {
  font-size: 5.5vw;
}

.details-edit-pencil {
  width: 8vw;
  width: 8vw;
  vertical-align: bottom;
}

.part-details-header {
  text-align: left;
  overflow-x: hidden;
  position: relative;
}

.part-details-part-number {
  font-size: 7vw;
  font-weight: bold;
}

.part-details-table {
  width: 100%;
}

.part-details-price-table-container {
  width: 100%;
  overflow-x: scroll;
}

.part-details-field {
  text-align: left;
  padding-left: 1vw;
}

.part-details-2col-field {
  text-align: left;
  max-width: 42vw;
}

.part-details-3col-field {
  text-align: left;
  max-width: 22vw;
}

.part-details-bin-container {
  right: 0;
  bottom: 2vw;
  font-size: 5vw;
  position: absolute;
}

.part-details-price-field {
  text-align: right;
  padding-right: 3vw;
  min-width: 3em;
  border-top: 1px solid var(--color-black-4);
}

.part-details-inv-desc-field {
  text-align: right;
  padding-right: 3vw;
  min-width: 6em;
  border-top: 1px solid var(--color-black-4);
}

.part-details-price-heading {
  text-align: left;
  padding-left: 3vw;
  font-weight: bold;
  border-top: 1px solid var(--color-black-4);
}

.part-details-group-depot-table.container {
  width: 100%;
  overflow-x: scroll;
}

.part-details-group-depot-table {
  width: 100%;
}

.part-details-group-depot-table.header {
  text-align: left;
  padding-left: 3vw;
  font-weight: bold;
  border-top: 1px solid var(--color-black-4);
}

.part-details-group-depot-table.heading {
  text-align: left;
  padding-right: 4vw;
  border-top: 1px solid var(--color-black-4);
}

.part-details-group-depot-table.field {
  text-align: left;
  padding-right: 4vw;
  min-width: 3em;
  border-top: 1px solid var(--color-black-4);
}

.slide-in {
  transform: translateX(0%) scaleX(100%);
  transition: transform 0.5s ease-in-out;
}

.slide-out {
  transform: translateX(200%) scaleX(0%);
  transition: transform 0.5s ease-in-out;
}

/* this is only for landscape */
.slide-out-top {
  transform: translateX(200%) scaleX(0%);
  transition: transform 0.5s ease-in-out;
}

.part-details-price-cost-divider {
  margin-top: 3vh;
}

.price-cost-toggle-container {
  height: 4vh;
  position: relative;
  display: block;
  margin-left: 6vw;
  width: 30vw;
}

.price-cost-toggle-and-label {
  width: 30vw;
}

.price-cost-toggle-label {
  position: relative;
  padding-bottom: 1vh;
  font-size: 4.5vw;
  width: 30vw;
  display: block;
}

.react-toggle-track {
  width: 15vw;
  height: 7vw;
}

.react-toggle-thumb {
  width: 6vw;
  height: 6vw;
  top: 0.5vw;
}

.react-toggle--checked .react-toggle-thumb {
  left: 8.5vw;
}

.price-span {
  overflow-x: scroll;
  display: flex;
  gap: 2vw;
  padding-bottom: 1vh;
}

.cost-span {
  overflow-x: scroll;
  display: flex;
  gap: 2vw;
  padding-bottom: 0vh;
}
@media (orientation: landscape) {
  .slide-in {
    transform: translateY(0%) scaleY(100%);
    transition: transform 0.5s ease-in-out;
  }

  .slide-out {
    transform: translateY(200%) scaleY(0%);
    transition: transform 0.5s ease-in-out;
  }

  .slide-out-top {
    transform: translateY(-200%) scaleY(0%);
    transition: transform 0.5s ease-in-out;
  }

  .part-details-container {
    top: 0vh;
    height: 100vh;
    left: 13vw;
    width: 74vw;
    z-index: 10;
  }
  
  .part-details-container-pla {
    top: 0vh;
    height: 100vh;
    left: 3vw;
    width: 82vw;
    z-index: 10;
  }

  .part-details-content {
    height: 80vh;
    width: 69vw;
    left: 0;
    padding-left: 3vw;
    font-size: 5vh;
  }
  
  .part-details-content-pla {
    height: 80vh;
    width: 75vw;
    left: 0;
    padding-left: 3vw;
    font-size: 5vh;
  }

  .part-details-description {
    font-size: 5vh;
    padding-left: 0vw;
    padding-top: 1vh;
    text-align: left;
  }

  .bin-details {
    font-size: 5.5vh;
  }

  .details-edit-pencil {
    width: 8vh;
    height: 8vh;
  }
  .part-details-part-number {
    font-size: 8vh;
  }

  .react-toggle-track {
    width: 15vh;
    height: 6vh;
  }
  .react-toggle-thumb {
    width: 5vh;
    height: 5vh;
    top: 0.5vh;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 9.5vh;
  }
  .price-cost-toggle-label {
    font-size: 5vh;
  }
}
