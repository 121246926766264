/* ----------- Filter ----------- */
.filter-option-toggle-label-cell {
  text-align: left;
  width: 75vw;
  height: 3vh;
  font-size: 3vh;
}

.filter-option-toggle-value-cell {
  text-align: right;
  vertical-align: text-bottom;
}

.filter-options-table[data-disabled="true"] {
  color: var(--color-black-4);
}

/* ----------- Sort ----------- */
#smart-sort-control {
  display: inline-block;
  height: 4vh;
  position: fixed;
  vertical-align: text-bottom;
}

.sort-options-row.sort-by {
  height: 6vh;
}

/* ----------- Checkboxes ----------- */
.checkbox-style[data-disabled="true"] {
  border: 1px solid var(--color-black-4);
}

.checkbox-style-red[data-disabled="true"] {
  border: 1px solid var(--color-black-4);
}

.checkbox-style[data-disabled="true"]::after {
  background-image: url("../../../assets/icons/grey-checkbox-checked.svg");
}
.checkbox-style-red[data-disabled="true"]:after {
  background-image: url("../../../assets/icons/grey-checkbox-checked.svg");
}

@media (orientation: landscape) {
  .flex-container {
    width: 75vw;
  }

  .divLabel,
  .divRadioButtons {
    max-width: 23vw;
  }

  .filter-option-toggle-label-cell {
    width: 35vw;
  }
  .filter-option-toggle-value-cell {
    width: 28.5vw;
  }

  .sort-options-row.sort-by {
    height: 1vh;
    max-height: 1vh;
    min-height: 1vh;
  }
}
