.module-container {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  font-size: 2vh;
  font-family: inherit;
  justify-content: center;
  align-items: center;
}

body {
  overscroll-behavior-y: contain;
}

.option-menu-header-wrapper {
  background-color: var(--color-black-1) !important;
  width: 100%;
  display: block;
  position: relative;
  z-index: 15;
}

#enquire-options-hamburger-menu {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  margin-left: 1vh;
  margin-right: 1vh;
  left: 0;
  right: 0;
  top: 15vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 14;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  justify-content: space-around;
}

.module-nav {
  color: var(--color-white);
  flex-wrap: nowrap;
  font-family: inherit;
}

.nav-top {
  background-color: var(--color-black-1);
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 2vh;
  min-height: 7vh;
}

.module-nav-button.top {
  width: 25vw;
  max-height: 10vh;
  font-size: 2vh;
}

.module-nav-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: var(--color-gold);
  background-color: var(--color-black-1);
  border: none;
  font-size: 1em;
  font-family: inherit;
  cursor: pointer;
}

.module-nav-button:disabled {
  color: var(--color-black-3);
  border-color: var(--color-black-3);
}

.module-nav-button-label[data-disabled="true"] {
  color: var(--color-black-3);
}

.module-nav-button-logo {
  height: 100%;
  width: 100%;
}

.active-tab {
  color: var(--color-white);
}

.module-nav-button-label {
  color: var(--color-white);
}

.module-content {
  position: fixed;
  top: 15vh;
  height: 60vh;
  width: 100%;
}

.search-results {
  position: fixed;
  top: 13.5vh;
  background-color: var(--color-black-2);
  width: 100%;
  height: 100%;
}

.search-results-pla {
  position: fixed;
  top: 13.5vh;
  background-color: var(--color-black-2);
  width: 100%;
  height: 100%;
}

.receive-search-results {
  position: fixed;
  top: 24vh;
  background-color: var(--color-black-2);
  width: 100%;
  height: 100%;
}

.receive-search-results.pos-lines {
  top: 27vh;
}

.receive-loading-gif {
  height: 40vh;
  width: 40vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.module-search {
  position: fixed;
  bottom: calc(9vh + (env(safe-area-inset-bottom) * 0.7));
  background-color: var(--color-black-2);
  width: 100%;
  z-index: 2;
}

.module-search-pla {
  position: fixed;
  bottom: calc(1vh + (env(safe-area-inset-bottom) * 0.7));
  background-color: var(--color-black-2);
  width: 100%;
  z-index: 2;
}

.search-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 2vh 1vw 2vh 5vw;
  max-height: 10vh;
  align-items: center;
}

.search-bar-safe-area-curtain {
  position: fixed;
  bottom: 0;
  background-color: var(--color-black-2);
  height: calc(17vh + (env(safe-area-inset-bottom) * 0.7));
  width: 100%;
  z-index: 1;
}

.module-search-term-input-text {
  border-radius: 15ch;
  width: 100%;
  max-width: 65vw;
  font-size: 1.7em;
  height: 7vh;
  padding-left: 5vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font-family: TitilliumWeb-Regular, NotoEmoji-Bold;
}

.module-search-term-input-text:focus {
  outline: none;
  border-style: solid;
  border-color: var(--color-gold);
}

.module-search-barcode-button {
  background-color: var(--color-black-2);
  border: none;
  max-width: 20%;
  height: 10vh;
  max-height: 10vh;
}

.module-search-barcode-button-logo {
  width: 100%;
  height: 100%;
  display: block;
  color: var(--color-white);
}

.nav-footer {
  position: fixed;
  z-index: 9;
  bottom: 0;
}

.nav-bottom {
  background-color: var(--color-black-1);
  display: flex;
  flex-direction: row;
  min-height: 7vh;
  padding-bottom: calc(0px + (env(safe-area-inset-bottom) * 0.7));
  align-items: center;
}

.module-nav-button.bottom {
  width: 20vw;
  max-height: 10vh;
}

#module-options-hamburger-menu {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  margin-left: 1vh;
  margin-right: 1vh;
  left: 0;
  right: 0;
  top: 15vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 14;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  justify-content: space-around;
}

.modal-toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.device-camera-toggle-label {
  font-size: large;
}

.finish-grn-grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  gap: 1vw;
  padding: 1vh;
}

.finish-grn-grid-item {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.finish-grn-grid-header {
  font-weight: bold;
}

.scrollable-line-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 30vh;
}

@media (orientation: landscape) {
  .nav-footer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 10vw;
  }

  #module-options-hamburger-menu {
    width: 70%;
    align-self: center;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 0;
  }

  .nav-bottom {
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column;
    padding-left: 0.5vw;
    min-width: 12vw;
    height: 100vh;
  }

  .module-nav {
    color: var(--color-white);
    font-family: inherit;
  }

  .module-nav-button {
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    height: 50vh;
  }

  .module-nav-button.bottom {
    width: 10vw;
    max-height: 16vh;
    padding-right: calc(0px + (env(safe-area-inset-bottom) * 0.7));
  }

  .module-nav-button.top {
    width: 10vw;
  }

  .nav-top {
    background-color: var(--color-black-1);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100vh;
    top: 0px;
    right: 0.5vw;
    z-index: 9;
    width: 12vw;
  }

  .module-content {
    position: fixed;
    top: 0vh;
    left: 8vw;
    height: 88vh;
    width: 82vw;
  }

  .module-search {
    position: fixed;
    left: 9vw;
    bottom: calc(0px + (env(safe-area-inset-bottom) * 0.7));
    background-color: var(--color-black-2);
    width: 76.5vw;
  }

  .search-bar-safe-area-curtain {
    height: calc(7vh + (env(safe-area-inset-bottom) * 0.7));
  }

  .module-search-term-input-text {
    font-size: 4vh;
    padding-left: 1vw;
    width: 80%;
    height: 8vh;
  }

  .module-search-barcode-button {
    max-width: 20%;
    height: 10vh;
    max-height: 10vh;
    cursor: pointer;
  }

  .search-results {
    position: fixed;
    top: 0vh;
    background-color: var(--color-black-2);
    width: 77vw;
    height: 100%;
  }
  
  .search-results-pla {
    position: fixed;
    top: 0vh;
    left: 0vw;
    background-color: var(--color-black-2);
    width: 88vw;
    height: 100%;
  }

  .receive-search-results {
    position: fixed;
    top: 15vh;
    background-color: var(--color-black-2);
    width: 77vw;
    height: 100%;
  }

  .receive-search-results.pos-lines {
    top: 19vh;
  }

  .nav-button {
    min-width: 9.1vw;
    min-height: 18vh;
    margin-left: 2vw;
  }

  .nav-button-label {
    padding-top: 1vh;
    font-size: 3vh;
  }
}

::-webkit-scrollbar {
  height: 1.5vh;
  width: 1.5vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-black-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  height: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: #555;
}