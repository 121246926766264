.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5vw;
  border: 0px  solid;
  overflow: hidden;
  width: fit-content;
  margin: 2vh auto;
}

.tab {
  padding: 1.7vw;
  padding-top: 1.3vh;
  padding-bottom: 1.2vh;
  cursor: pointer;
  min-width: 26.5vw;
  border: 1px solid var(--color-black-3);
  background-color: var(--color-black-4);
  font-weight: bold;
}

.tab.two-options{
  padding: 1.7vw;
  padding-top: 1.3vh;
  padding-bottom: 1.2vh;
  cursor: pointer;
  min-width: 40.5vw;
  border: 1px solid var(--color-black-3);
  background-color: var(--color-black-4);
  font-weight: bold;
}

.tab.selected {
  background-color: var(--color-gold);
  color: var(--color-black-1);
}

.tab:last-child {
  border-right: none;
}


@media (orientation: landscape) {
  .tab {
    padding: 1.7vw;
    padding-top: 1.3vh;
    padding-bottom: 1.2vh;
    min-width: 21vw;
    font-size: 2vw;
  }
  .tab.two-options{
    padding: 1.7vw;
    padding-top: 1.3vh;
    padding-bottom: 1.2vh;
    min-width: 30vw;
    font-size: 2vw;
  }
}