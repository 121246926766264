.nav-button {
  font-family: TitilliumWeb-Regular;
  font-size: 2vh;
  border-style: solid;
  border-width: 3px;
  border-radius: 25%;
  min-width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nav-button-icon-surround {
  order: 1;
  flex-grow: 2;
  max-height: 10vh;
  max-width: 12vw;
  width: 10vh;
}

.nav-button-label {
  color: var(--color-white);
  order: 2;
  flex-grow: 1;
}

.modal-button {
  font-family: TitilliumWeb-Regular;
  font-size: 2vh;
  border-style: solid;
  border-width: 3px;
  border-radius: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 27vw;
  min-height: 11vh;
  margin-right: 0.2vw;
}

@media (orientation: landscape) {
  .modal-button {
    min-width: 8vw;
    min-height: 14vh;
  }
  .nav-button-icon-surround {
    order: 1;
    flex-grow: 2;
    max-height: 10vh;
    max-width: 10vw;
  }
}
