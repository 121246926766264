/* ----------- Enquire ----------- */
.parts-list-description {
  padding-left: 4%;
  padding-bottom: 1%;
  font-style: italic;
  display: flex;
  align-self: flex-start;
}

.parts-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: 82vh;
  font-family: TitilliumWeb-Regular;
  font-size: 2vh;
}

.parts-list-item {
  color: var(--color-white);
  width: 92.5%;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 16.5vh;
  cursor: pointer;
}

.parts-list-item-transparent {
  opacity: 0.9;
}

.part-item-header {
  background-color: var(--color-black-3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 0px 1% 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.part-name {
  font-size: 1.45em;
  font-weight: bold;
}

.part-card-circle-pin {
  position: absolute;
  top: 1.5vw;
  right: 2vw;
  background-color: var(--color-gold);
  border-radius: 50%;
  font-size: 3vh;
  width: 3.5vh;
  height: 3.5vh;
  text-align: center;
  line-height: 3vh;
  color: var(--color-black-1);
  z-index: 1;
}

.po-part-name {
  display: grid;
  width: 100%;
  grid-template-columns: 10fr 6fr;
  font-size: 1.45em;
  font-weight: bold;
  align-items: center;
  justify-items: start;
}

.po-part-name>span {
  font-weight: normal;
  color: #dbb25d;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.5em;
  justify-self: end;
  padding: 0 10px;
  width: max-content;
}

.part-description {
  font-size: 1.1em;
  overflow: hidden;
}

.part-item-values {
  background-color: var(--color-black-4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 3%;
  display: flex;
  flex-direction: row;
}

.part-item-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.part-item-value-content {
  font-weight: bold;
}

/* ----------- Receive & Order lines ----------- */
.product-order-list-item {
  color: var(--color-white);
  width: 92.5%;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 19.75vh;
  cursor: pointer;
}

.purchase-order-lines-header {
  background-color: var(--color-black-3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 0px 1% 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

details summary::-webkit-details-marker {
  display: none;
}

.order-lines-list-item>summary {
  cursor: pointer;
}

.order-lines-list-item[open]>summary {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.order-lines-values {
  position: relative;
  z-index: 1;
  background-color: var(--color-black-4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.order-lines-values.animate {
  -webkit-animation: both;
  animation: openDetails 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

@keyframes openDetails {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.product-order-header {
  background-color: var(--color-black-3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 3%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.purchase-order-lines-blue-button {
  background-color: var(--color-blue);
}

.purchase-order-lines-submit-button {
  background-color: var(--color-green);
}

.purchase-order-lines-edit-button {
  background-color: var(--color-blue);
}

/* remove input arrows for Firefox */
.purchase-order-lines-controls>input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: center;
  font-size: x-large;
  font-weight: bolder;
  font-family: inherit;
}

/* remove input arrows for all other browsers */
.purchase-order-lines-controls>input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.purchase-order-values {
  background-color: var(--color-black-4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 3%;
  display: flex;
  flex-direction: row;
}

.purchase-order-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 500px) {
  .purchase-order-values {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". ."
      ". .";
  }

  .purchase-order-value {
    flex-direction: row;
  }
}

.order-lines-list-item {
  color: var(--color-white);
  width: 92.5%;
  padding-bottom: 2vh;
}

.order-lines-transparent {
  opacity: 0.65;
}

.line-name {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 1fr;
  font-size: 1.45em;
  font-weight: bold;
  align-items: center;
  justify-items: start;
}

.serial-name {
  grid-template-columns: auto 5fr 1fr;
}

#serial-part-button {
  color: white;
  font-size: xx-large;
}

.purchase-order-lines-value {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 0;
}

.purchase-order-lines-controls {
  width: 100%;
  display: flex;
}

.purchase-order-lines-controls :first-child {
  border-bottom-left-radius: 10px;
}

.purchase-order-lines-controls :last-child {
  border-bottom-right-radius: 10px;
}

.purchase-order-lines-button {
  width: 10vw;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  border: none;
  align-items: center;
}

.purchase-order-lines-controls .lone-edit-button {
  flex-grow: 0;
  flex-shrink: 0;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  border: none;
}

.purchase-order-lines-controls-spacer {
  flex-grow: 0;
  flex-shrink: 0;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  border: none;
}

.purchase-order-lines-controls input {
  width: 9vw;
  flex-grow: 1;
  padding: 0 5px;
  box-sizing: border-box;
  border: none;
}

.purchase-order-page-header-line {
  display: flex;
  justify-content: space-between;
  padding-left: 8vw;
  padding-right: 8vw;
  align-items: center;
}

.purchase-order-page-header-lines {
  color: var(--color-blue);
}

.purchase-order-item {
  flex: 1;
  padding: 0 5vw;
  /* Optional: Adjust as needed for spacing between items */
}

.purchase-order-page-header-desc {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.purchase-order-page-header-po {
  text-decoration: underline;
}

.modal-page {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  height: auto;
  left: 0;
  right: 0;
  margin: 0vh 1vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 14;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  color: white;
  font-size: medium;
  top:10vh;
}

.info-page {
  top: 6vh;
}

.finish-page {
  max-height: 80vh;
}

.modal-page-scrollable {
  overflow-x: auto;
  overflow-y: auto;
  padding: 1vh 1vh 0 0;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  width: auto;
}

.info-page-scrollable {
  max-height: 65vh;
}

.finish-page-scrollable {
  max-height: 76vh;
}

.info-page-scrollable > div {
  display: flex;
  column-gap: 2vw;
}

.info-page-header {
  align-items: center;
}


.info-page-surcharge-section {
  flex-direction: column;
}

#info-page-title {
  font-weight: bold;
  font-size: x-large;
}

#finish-page-modal-mask {
  z-index: -1;
}

#finish-page-title {
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}

#info-page-description {
  text-overflow: ellipsis;
}

#info-page-received {
  display: flex;
  justify-content: flex-end;
  color: var(--color-blue);
}

.info-page-section {
  flex-direction: column;
}

.info-page-label {
  width: max-content;
  overflow: unset;
  text-align: left;
}

.info-page-message {
  margin: 0.5rem 0rem;
}

.info-page-bordered-value {
  background-color: var(--color-black-2);
  color: white;
  overflow: unset;
  text-align: center;
  border: solid 0.1rem var(--color-blue);
  border-radius: 0.5rem;
}

.info-page-surcharge-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 3vw;
  width: 100%;
}

.info-page-surcharge-grid > div {
  display: flex;
  align-items: center;
}

.info-page-surchage-label {
  text-align: center;
}

.info-page-input-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.info-page-cost-input {
  display: flex;
  height: 1.4rem;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.05rem;
  justify-content: center;
  align-items: center;
}

.info-page-cost-input:focus {
  outline: none;
}

.info-page-cost-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.info-page-cost-button {
  display: flex;
  height: 10vh;
  width: 100%;
  background-color: black;
  flex-direction: column;
  align-items: center;
}

.info-page-save-button {
  border-color: var(--color-green);
}

.info-page-cancel-button {
  border-color: var(--color-red);
}

.serial-page-items-header {
  display: flex;
  align-items: center;
}

#serial-page-items-hr {
  width: 85%;
  height: fit-content;
  border-color: grey;
}

.serial-page-grid {
  padding-top: 5px;
  display: grid;
  grid-template-columns: 0.8fr 0.5fr 1fr;
  row-gap: 20px;
}

#serial-scroll-div {
  height: 30vh;
  overflow-y: auto;
}

#serial-grid-part {
  width: fit-content;
  float: left;
  color: grey;
}

#serial-grid-serial {
  width: fit-content;
  color: grey;
  justify-self: center;
}

.serial-page-part-number {
  font-size: medium;
}

.serial-page-index {
  color:white;
  width: 4vh;
  height: fit-content;
  justify-self: center;
}

.serial-page-input {
  justify-self: center;
  color: white;
  background-color: var(--color-black-2);
  border: 1px solid var(--color-blue);
  border-radius: .5rem;
  text-align: center;
  font-size: medium;
  height: 4vh;
  width: 100%;
  margin-right: 5vw;
}

/* ----------- SVG ----------- */
.pencil-icon {
 padding-left: 2px;
}

.info-page-save-icon {
  color: var(--color-green);
}

.info-page-cancel-icon {
  color: var(--color-red);
}

.info-page-cost-button > svg {
  width: 3rem;
  height: 3rem;
}

.info-icon-svg {
  padding: 10px;
  justify-self: end;
  height: 1.5rem;
  width: auto;
}

.serial-icon-svg {
  justify-self: start;
  height: 1.5rem;
  width: auto;
}

.purchase-order-lines-controls>button>* {
  width: auto;
  height: 2.25rem;
}

#exit-icon {
  rotate: 45deg;
  font-size: 45px;
  height: max-content;
  width: max-content;
  z-index: 14;
  margin: 0;
}

/* ----------- Misc. ----------- */
.hidden-titillium {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}

p {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0;
  margin: 0;
}

.fetching-items-container {
  padding-top: 10%;
  padding-bottom: 70%;
  justify-content: "center";
  text-align: "center";
}

.fetching-items-logo {
  max-width: 20vw;
}

@media (orientation: landscape) {

  #finish-page-title {
    font-size: 7vh;
  }

  .info-page-cost-buttons {
    justify-content: flex-end;
  }

  .finish-page {
    left: 13vw;
    width: calc(71vw + (env(safe-area-inset-bottom) * 0.4));
    height: 90vh;
    font-size: 4vh;
    max-height: 90vh;
  }


  .parts-list-container {
    padding-left: env(safe-area-inset-bottom);
  }

  .modal-page {
    top: 1vw;
  }

  .scrollable-line-list {
    font-size: 3vh;
  }
  .fetching-items-logo {
    max-width: 5vw;
  }

  .parts-list-item {
    min-height: 18vh;
  }

  .fetching-items-container {
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .line-name {
    font-size: 2em;
  }

  .part-description {
    font-size: 1.3em;
  }

  .order-lines-list-item {
    padding-bottom: 3vh;
  }

  .product-order-list-item {
    min-height: 21.5vh;
  }

  .info-page {
    left: 13vw;
    width: 70vw;
    height: fit-content;
    padding-top: 0;
    font-size: small;
  }

  #info-page-title {
    font-size: medium;
  }

  .info-page-section {
    min-height: max-content;
    flex-direction: row;
  }

  .info-page-surcharge-grid {
    gap: 1vw;
    column-gap: 0;
  }

  .info-page-surchage-label {
    text-align: right;
    padding-right: 25px;
  }
  
  .serial-page-index {
    display: flex;
    width: 30%;
    height: auto;
    font-size: x-large;
    justify-content: center;
  }

  .serial-page-input {
    height: auto;
    font-size: larger;
  }

  .serial-page-part-number {
    font-size: x-large;
  } 
}