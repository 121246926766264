.headed-value-box {
    background-color: var(--color-black-3);
    border-radius: 1.5vw;
}

.headed-value-box-header {
    min-width: 19vw;
    border-top-left-radius: 1.5vw;
    font-weight: bold;
    border-top-right-radius: 2vw;
    background-color: var(--color-black-4);
    padding: 0 2vh;
}

.headed-value-box-value{
    min-width: 19vw;
}