.loading-mask {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: var(--color-black-1);
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1000;
}

.loading-mask-text {
    font-size: 5vh;
    color: var(--color-white);
}

.loading-mask-icon {
    align-self: center;
    height: 20vh;
    width: 20vh;
}

.greyed-out {
    opacity: 0.3;
}