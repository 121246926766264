/* ----------- Header ----------- */
.home-header-wrapper {
  background-color: var(--color-black-1) !important;
  width: 100%;
  display: block;
  position: relative;
  z-index: 3;
}

.home-header {
  display: flex;
  background-color: var(--color-black-1);
  position: relative;
  justify-content: space-between;
  align-items: center;
  columns: 3;
  flex-direction: row;
  padding-top: 2vh;
  padding-bottom: 2vh;
  width: 100vw;
  min-height: 7vh;
  z-index: 3;
}

.home-header-hamburger-icon-button {
  position: relative;
  left: 4vw;
  height: 5vh;
  max-width: 12vw;
  background-color: var(--color-black-1);
  flex: 1;
}

.home-header-hamburger-icon-svg {
  height: 100%;
  width: 100%;
}

.home-header-scan-icon-button {
  position: relative;
  right: 5vw;
  height: 7vh;
  max-width: 13vw;
  padding: 0;
  background-color: var(--color-black-1);
  flex: 1;
  opacity: 0;
  border-style: none;
}

.home-header-scan-icon-svg {
  height: 100%;
  width: 100%;
}

.home-header-ibcos-logo {
  height: 7vh;
  max-width: 55vw;
}

/* ----------- Hamburger Menu  ----------- */
.modal-open {
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  margin-top: 0vh;
  margin-bottom: 0vh;
  opacity: 1;
}

.modal-closed {
  margin-top: -20vh;
  pointer-events: none;
  opacity: 0;
}

.modal-explainer-text {
  font-size: 2.9vh;
  padding: 0 4vw;
}
.modal-explainer-text-small {
  font-size: 2.3vh;
  padding: 0 4vw;
}

#home-header-hamburger-menu {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  margin-left: 1vh;
  margin-right: 1vh;
  left: 0;
  right: 0;
  top: 15vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 14;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  justify-content: space-around;
}

#home-header-change-depot-modal {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  margin-left: 1vh;
  color: var(--color-white);
  margin-right: 1vh;
  right: 0;
  top: 15vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 10;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  width: 87vw;
  justify-content: space-around;
}

#barcode-search-modal {
  background-color: var(--color-black-2);
  text-justify: center;
  position: fixed;
  border-style: solid;
  border-width: 1vh;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  margin-left: 1vh;
  color: var(--color-white);
  margin-right: 1vh;
  left: 0;
  right: 0;
  top: 1vh;
  height: 89vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  columns: 1;
  z-index: 10;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  justify-content: baseline;
}

.home-header-hamburger-button {
  color: var(--color-white);
  background-color: var(--color-black-3);
  font-size: 8vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-radius: 1vh;
  width: 100%;
  font-family: inherit;
  left: 0;
  border-width: 0.5vh;
  border-color: var(--color-blue);
}

.home-header-hamburger-button:disabled {
  color: var(--color-black-3);
  background-color: var(--color-black-2);
  cursor: not-allowed;
  opacity: 0.6;
  border-color: var(--color-black-4);
}

/* ----------- Main Menu Buttons ----------- */
.main-menu {
  background-color: var(--color-black-2);
  height: 100%;
}

.main-menu-button-container {
  background-color: var(--color-black-2);
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  gap: 1.5vh;
  padding-top: 2vh;
  padding-bottom: 18vh;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.main-menu-button {
  font-weight: normal;
  font-family: inherit;
  border-radius: 0.2em;
  border-style: solid;
  background-color: var(--color-black-3);
  border-color: var(--color-blue);
  border-width: 1px;
  font-size: 7vh;
  cursor: pointer;
  width: 90%;
}

.main-menu-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  width: 100%;
}
.main-menu-button:disabled {
  opacity: 0.4;
  border-style: none;
}

.main-menu-button-text {
  font-size: 7vh;
  text-align: left;
  font-family: inherit;
  color: var(--color-white);
  padding-left: 3%;
  position: absolute;
  left: 8%;
}

.main-menu-button-icon-surround {
  color: var(--color-gold);
  position: absolute;
  right: 10%;
  height: 9vh;
  width: 9vh;
}

.main-menu-button-icon-surround[data-disabled="true"] {
  color: var(--color-white);
}

@media (orientation: landscape) {
  .home-header-hamburger-button {
    font-size: 6vw;
  }
}

@media screen and (min-aspect-ratio: 5/3) {
  .main-menu-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 100%;
    position: relative;
  }
  .main-menu-button-container {
    flex-direction: row;
    column-count: 2;
    align-items: center;
    gap: 4vh;
    justify-content: center;
    padding-bottom: 9vh;
    margin-top: 2vh;
    padding-top: 6vh;
    flex-wrap: wrap;
  }
  .main-menu-button {
    width: 42%;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 2vh;
    height: 25vh;
  }

  .main-menu-button-text {
    font-size: 13vh;
    text-align: left;
    font-family: inherit;
    color: var(--color-white);
    padding-left: 2%;
    position: absolute;
    left: 1%;
  }

  .main-menu-button-icon-surround {
    color: var(--color-gold);
    position: absolute;
    right: 1%;
    height: 15vh;
    width: 15vh;
  }

  .main-menu-footer {
    padding-bottom: 1em;
    font-size: 5vh;
  }

  .main-menu-banner {
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .main-menu-banner {
    font-size: 4vh;
  }

  .main-menu-ibcos-logo {
    height: 15vh;
  }

  .main-menu-hamburger {
    height: 16vh;
    padding-top: 3vh;
  }
}

/* ----------- Home Footer ----------- */
.main-menu-footer {
  display: flex;
  position: fixed;
  font-size: 3vh;
  background-color: var(--color-black-1);
  bottom: 0;
  padding-left: 5%;
  padding-bottom: 1vh;
  padding-right: 10%;
  width: 90%;
  max-height: 15vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-style: solid;
  border-color: var(--color-gold);
  border-width: 2px 0px 0px;
}

.main-menu-footer-icon {
  flex: 1;
  height: 15vh;
  color: var(--color-black-3);
  justify-content: center;
  align-items: center;
  align-content: center;
}

.main-menu-footer-text {
  flex: 4;
  color: var(--color-white);
  padding-top: 3%;
  font-size: inherit;
  text-align: right;
}
