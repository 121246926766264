/* ----------- Flex ----------- */
.flex-container {
  background-color: #1a1a1a;
  color: var(--color-white);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1vh;
  width: 95vw;
  height: 70vh;
}

.flex-items {
  font-size: 2vh;
}

.flex-level1-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  /* font-size: 2em; */
}

/* ----------- Sort ----------- */ 
.sort-options-row {
  height: 8vh;
}

.sort-and-filter-header {
  margin-bottom: 1vh;
  margin-top: 0;
}

/* ----------- Filter ----------- */
.filter-option-table {
  border-spacing: 0 0.9vh;
}

.filter-options-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 93vw;
  padding: 0;
  margin: 0;
}


.filter-search-box {
  height: 3vh;
  font-size: 2.5vh;
  font-family: TitilliumWeb-Regular, NotoEmoji-Bold;
  padding-left: 2vw;
  border-radius: 1vh;
  text-align: left;
  width: 75%;
}

.filter-search-box:focus {
  outline: none;
  border-style: solid;
  border-color: var(--color-gold);
}

.filter-options-list-table {
  overflow: scroll;
}

.filter-options-list-table-container {
  height: 37.5vh;
  overflow: scroll;
}

.filter-option-label-cell {
  text-align: left;
  width: 40vw;
  font-size: 1.9vh;
}
.filter-option-label-cell-extended {
  text-align: left;
  width: 80vw;
  height: 5vh;
  font-size: 3vh;
}

.filter-option-value-cell {
  text-align: right;
  padding-left: 2vw;
}

.filter-option-checkbox-cell {
  width: 23.5vw;
  text-align: right;
}

/* ----------- Checkboxes ----------- */
.checkbox-label {
  font-size: 2.2vh;
}

.checkbox-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 3vh;
  height: 3vh;
  border: 1px solid var(--color-blue);
  border-radius: 1vw;
  background-color: var(--color-black-2);
  vertical-align: middle;
  margin: 0;
  margin-left: 10px;
}

.checkbox-style-red {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 3vh;
  height: 3vh;
  border: 1px solid var(--color-red);
  border-radius: 1vw;
  background-color: var(--color-black-2);
  vertical-align: middle;
  margin: 0;
  margin-left: 10px;
}

.checkbox-style:checked {
  background-color: var(--color-black-2);
}

.checkbox-style:checked:after {
  content: "";
  display: block;
  background-image: url("../../../assets/icons/blue-checkbox-checked.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 2.8vh;
  height: 2.5vh;
}

.checkbox-style-red:checked {
  background-color: var(--color-black-2);
}

.checkbox-style-red:checked:after {
  content: "";
  display: block;
  background-image: url("../../../assets/icons/red-checkbox-checked.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 2.8vh;
  height: 2.5vh;
}

/* ----------- SVG & Button ----------- */
.divImageAndLabel {
  display: flex;
  border-style: solid;
  border-color: var(--color-blue);
  border-radius: 1vh;
  border-width: 1px;
  overflow: hidden;
  text-align: left;
  align-items: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 2vw;
  flex-grow: 2;
  height: 2vh;
  font-size: 1.9vh;
  white-space: nowrap;
  overflow: hidden;
  width: 45vw;
}

.clear-selection-button {
  height: 4vh;
  max-width: 17%;
  background-color: var(--color-black-1);
  border-color: var(--color-blue);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  font-family: TitilliumWeb-Regular;
  font-weight: bold;
  font-size: 1.75vh;
  color: var(--color-blue);
  justify-content: center;
  align-items: center;
}

.edit-pencil {
  height: 3vh;
}

/* ----------- Model ----------- */
.modal-open {
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  margin-top: 0vh;
  margin-bottom: 0vh;
  opacity: 1;
}

.modal-closed {
  opacity: 0;
}

#search-and-filter-modal {
  top: 14vh;
  position: fixed;
  z-index: 10;
  background-color: var(--color-black-2);
  border-style: solid;
  border-width: 1.5vw;
  border-radius: 3vh;
  border-color: var(--color-black-4);
  width: 95vw;
  height: 71.6vh;
  padding: 1vw;
}

.filter-modal-button-span {
  bottom: 0px;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 10vw;
  position: absolute;
}

.filter-modal-contents {
  top: 16vh;
  width: 93vw;
  position: fixed;
  color: var(--color-white);
  z-index: 13;
  background-color: var(--color-black-2);
  border-color: var(--color-black-4);
  padding: 1vw;
  height: 69.8vh;
}

.filter-block-container {
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) {
  .flex-container {
    width: 75vw;
  }

  /* ----------- Filter Landscape ----------- */
  .filter-options-search-container {
    width: 70vw;
  }

  .filter-search-box {
    height: 5vh;
    font-size: 4vh;
    width: 75%;
  }

  .filter-option-label-cell {
    text-align: left;
    min-width: 10vw;
    font-size: 1.5vw;
    height: 7vh;
  }

  .filter-option-value-cell {
    text-align: right;
  }

  .filter-option-table {
    max-height: 2vh;
  }

  .filter-options-list-table-container {
    height: 28vh;
  }

  .filter-block-container {
    display: flex;
    flex-direction: row;
    gap: 1vw;
  }

  .filter-modal-contents {
    top: 3vh;
    left: 14vw;
    width: 70vw;
    padding: 1vw;
    height: 72vh;
    overflow: hidden;
  }

  #search-and-filter-modal {
    top: 1vh;
    left: 13vw;
    width: 71vw;
    height: 74vh;
    border-width: 1vh;
  }
  
  /* ----------- SVG & Button Landscape ----------- */
  .clear-selection-button {
    height: 7vh;
    max-width: 15%;
    font-size: 3.5vh;
  }

  .edit-pencil {
    height: 5vh;
  }

  .divLabel,
  .divRadioButtons {
    max-width: 23vw;
  }

  .divImageAndLabel {
    width: 23vw;
    height: 5vh;
    font-size: 3vh;
    padding-left: 1vw;
  }

  /* ----------- Checkboxes Landscape ----------- */
  .checkbox-label {
    font-size: 1.6vw;
  }

  .checkbox-style {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 2vw;
    height: 2vw;
    border: 1px solid var(--color-blue);
    border-radius: 0.5vw;
    background-color: var(--color-black-2);
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
  }

  .checkbox-style:checked {
    background-color: var(--color-black-2);
  }

  .checkbox-style:checked:after {
    content: "";
    display: block;
    background-image: url("../../../assets/icons/blue-checkbox-checked.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 2vw;
    height: 2vw;
  }

  .checkbox-style-red {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 2vw;
    height: 2vw;
    border: 1px solid var(--color-red);
    border-radius: 0.5vw;
    background-color: var(--color-black-2);
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
  }

  .checkbox-style-red:checked {
    background-color: var(--color-black-2);
  }

  .checkbox-style-red:checked:after {
    content: "";
    display: block;
    background-image: url("../../../assets/icons/red-checkbox-checked.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 2vw;
    height: 2vw;
  }
}

@media ((min-aspect-ratio: 2.3/1) and (-webkit-min-device-pixel-ratio: 2.3)) {
  .filter-modal-contents {
    height: 90vh;
  }
  #search-and-filter-modal {
    height: 92vh;
  }
}
