.barcode-page-wrapper {
  font-size: 4vh;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-button-span.camera-mode {
  padding-top: 0;
}

.barcode-ui-divider {
  border-color: var(--color-black-2);
  border-bottom: 1px;
  border-style: solid;
  width: 80%;
  margin-bottom: 1vh;
}

.barcode-microcard-collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5vh;
  width: 100%;
  height: 25vh;
  overflow-y: scroll;
}

.barcode-microcard {
  background-color: var(--color-black-3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 3%;
  padding-bottom: 1%;
  margin-bottom: 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.camera-view-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 35vh;
  justify-content: center;
  align-items: center;
}

.barcode-render-box {
  height: 100%;
  width: 80%;
  position: relative;
  display: flex;
}

video {
  height: 100%;
  width: 100%;
  border-radius: 1ex;
}
canvas {
  height: 100%;
  width: 100%;
  position: absolute;
}

@media ((min-aspect-ratio: 1/1.7) and (max-aspect-ratio:1/1.2)) {
  .barcode-microcard-collection {
    height: 22vh;
  }
}

@media ((min-aspect-ratio: 1/1.3) and (max-aspect-ratio:1/1)) {
  .barcode-microcard-collection {
    height: 18vh;
  }
}

@media (orientation: landscape) {
  .barcode-page-wrapper {
    flex-direction: row;
  }

  .barcode-ui-divider {
    border-bottom: 0;
    border-left: 1px;
    border-style: solid;
    width: 1%;
    height: 80%;
  }

  .barcode-microcard-collection {
    height: 40vh;
  }

  .camera-view-wrapper {
    width: 80vw;
    height: 45vh;
  }
}
