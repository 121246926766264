html {
  --color-black-1: #0e0e0e;
  --color-black-2: #1a1a1a;
  --color-black-3: #444444;
  --color-black-4: #575757;
  --color-gold: #dbb25d;
  --color-green: #4bb74d;
  --color-blue: #397cfc;
  --color-red: #c12843;
  --color-gold-hi: #E4C687;
  --color-green-hi: #6FC671;
  --color-blue-hi: #6B9DFD;
  --color-red-hi: #D8445E;
  --color-white: #ffffff;
  background-color: var(--color-black-1);
  font-family: TitilliumWeb-Regular;
}

html, body {
  touch-action: manipulation; /* Optimize for touch devices */
  overscroll-behavior-y: contain; /* Disable pull-to-refresh effect on iOS */
  -webkit-text-size-adjust: 100%;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App-logo {
  margin-top: 5vh;
  pointer-events: none;
  max-width: 80vw;
  max-height: 10vh;
  margin-bottom: 10vh;
}

/*  Color Sets for calling in from an Enume */
.black1-foreground {
  color: var(--color-black-1);
  border-color: var(--color-black-1);
}
.black2-foreground {
  color: var(--color-black-2);
  border-color: var(--color-black-2);
}
.black3-foreground {
  color: var(--color-black-3);
  border-color: var(--color-black-3);
}
.black4-foreground {
  color: var(--color-black-4);
  border-color: var(--color-black-4);
}
.gold-foreground {
  color: var(--color-gold);
  border-color: var(--color-gold);
}
.green-foreground {
  color: var(--color-green);
  border-color: var(--color-green);
}
.blue-foreground {
  color: var(--color-blue);
  border-color: var(--color-blue);
}
.red-foreground {
  color: var(--color-red);
  border-color: var(--color-red);
}
.gold-hi-foreground {
  color: var(--color-gold-hi);
  border-color: var(--color-gold-hi);
}
.green-hi-foreground {
  color: var(--color-green-hi);
  border-color: var(--color-green-hi);
}
.blue-hi-foreground {
  color: var(--color-blue-hi);
  border-color: var(--color-blue-hi);
}
.red-hi-foreground {
  color: var(--color-red-hi);
  border-color: var(--color-red-hi);
}
.white-foreground {
  color: var(--color-white);
  border-color: var(--color-white);
}

.black1-background {
  background-color: var(--color-black-1);
}
.black2-background {
  background-color: var(--color-black-2);
}
.black3-background {
  background-color: var(--color-black-3);
}
.black4-background {
  background-color: var(--color-black-4);
}
.gold-background {
  background-color: var(--color-gold);
}
.green-background {
  background-color: var(--color-green);
}
.blue-background {
  background-color: var(--color-blue);
}
.red-background {
  background-color: var(--color-red);
}
.white-background {
  background-color: var(--color-white);
}

/* ----------- Models ----------- */
.modal-mask {
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  background-color: var(--color-black-1);
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 10;
}
.modal-header-text {
  font-size: 5vh;
  border-bottom-width: 1px;
}

.modal-header-underline {
  width: 75%;
}

.modal-selector {
  align-self: center;
  text-align: center;
  border-radius: 5vh;
  font-family: inherit;
  font-size: 3vh;
  width: 80%;
}

.modal-button-span {
  padding-top: 3vh;
  padding-bottom: 3vh;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  gap: 10vw;
}

/* ----------- SVG ----------- */
svg {
  display: block;
}

@media (orientation: landscape) {
  svg {
    display: block;
  }
}

/* ----------- HR ----------- */
.hr-container {
  display: flex;
  align-items: center;
}

.hr-label {
  margin-right: 3vw;
  font-size: 4vw;
  color: var(--color-black-4);
}

.hr-line {
  margin-top: 1.5vh;
  flex-grow: 1;
  height: 2px;
  background-color: var(--color-black-4);
  border: none;
}

.hr-divider {
  border: 1px solid var(--color-black-4);
}

/* ----------- Misc. ----------- */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-green) !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: var(--color-green) !important;
}

@media (orientation: landscape) {
  .hr-label {
    margin-right: 3vw;
    font-size: 4vh;
    color: var(--color-black-4);
  }
}